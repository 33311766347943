import { getRequests } from "utils/requests";

const requests = getRequests("Articles");

const headers = {
  type: "CRUD",
  options: {
    name: "Producto",
    route: { path: "/articles" },
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
    },
  },
  sections: [
    {
      title: "Producto",
      fields: [
        {
          property: "reference",
        },
        {
          property: "description",
        },
        {
          property: "sellPrice",
        },
        {
          property: "vat",
        },
        {
          property: "purchasePrice",
          upsertOptions: {
            show: false,
          },
        },
      ],
    },
  ],
};

export default headers;
