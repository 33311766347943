import { getRequests } from "utils/requests";

const requests = getRequests("Customers");

const headers = {
  type: "CRUD",
  options: {
    name: "Cliente",
    route: { path: "/customers" },
    requests,
    tableOptions: {
      isEditable: true,
      isDeletable: true,
    },
  },
  sections: [
    {
      title: "Cliente",
      fields: [
        {
          property: "name",
        },
        {
          property: "firstName",
        },
        {
          property: "lastName",
        },
        {
          property: "alias",
        },
        {
          property: "mobilePhone",
        },
        {
          property: "email",
        },
      ],
    },
  ],
};

export default headers;
