import React from "react";
import ReactDOM from "react-dom";
import { contexts } from "@neoco/neoco-backoffice";
import reportWebVitals from "./reportWebVitals";
import DummyApp from "./App";
import 'languages/i18next';

const { AuthProvider, ConfigProvider } = contexts;

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ConfigProvider>
        <DummyApp />
      </ConfigProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();