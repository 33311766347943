import { pages } from "@neoco/neoco-backoffice";
import { loginRequest } from "utils/requests";

const { Login } = pages;

const headers = {
  type: "Page",
  options: {
    name: "Login",
    route: {
      path: "/",
      unAuth: true,
      auth: false,
      exact: true,
      component: (props) => (
        <Login
          {...props}
          onSubmit={loginRequest}
          recoverPassword={{ linkText: "Recuperar contraseña", to: "/home" }}
          register={{ linkText: "Crear cuenta", to: "/no-home" }}
        />
      ),
    },
  },
};

export default headers;