import request from "./request";

export const loginRequest = (credentials) =>
  request(`ApplicationUsers/login/?include=USER`, {
    method: "POST",
    body: JSON.stringify(credentials),
  }).then((response) => ({
    token: response.id,
    user: response.user
  }));

  export const findRequest =
  (endpoint, defaultFilter) =>
  ({ filter, sort, pagination } = { pagination: { page: 1, pageSize: 5 } }) => {
    const offset = pagination?.page * pagination?.pageSize;
    const limit = pagination?.pageSize;
    const encodedURI = `${endpoint}/?filter=${encodeURI(
      JSON.stringify(
        {
          offset,
          limit,
        }
      )
    )}`;
    const isFiltering = filter && filter.length;
    const isSorting = sort && sort.length;

    const itemToSend = {
      where: formatFilter(filter),
      offset,
      limit,
      ...(isSorting
        ? {
            order: [
              Object.values(sort[0]).map((element, index) =>
                index === Object.values(sort[0]).length - 1
                  ? element.toUpperCase()
                  : element
              ),
            ],
          }
        : {}),
    };
    return isFiltering || isSorting
      ? request(`${endpoint}/`, {
          method: "POST",
          body: JSON.stringify(itemToSend),
        })
      : request(encodedURI);
  };

export const countRequest =
  (endpoint, defaultFilter = {}) =>
  ({ filter }) =>
    request(
      `${endpoint}/count/?filter=${encodeURI(
        JSON.stringify(
          {
            ...(filter && filter.length ? formatFilter(filter) : {}),
          }
        )
      )}`
    );

export const upsertRequest = (endpoint) => (data) =>
  request(`${endpoint}`, {
    method: "PATCH",
    body: JSON.stringify(data),
  });

export const findOneRequest =
  (endpoint) =>
  ({ id }) =>
    request(`${endpoint}/${id}`);

    export const deleteRequest =
  (endpoint) =>
  ({ id }) =>
    request(`${endpoint}/${id}`, {
      method: "DELETE",
    });

export const getRequests = (endpoint, options) => ({
  findRequest: findRequest(endpoint, options?.findRequest?.defaultFilter),
  findOneRequest: findOneRequest(endpoint),
  upsertRequest: upsertRequest(endpoint),
  countRequest: countRequest(endpoint, options?.findRequest?.defaultFilter),
  deleteRequest: deleteRequest(endpoint),
});

const formatFilter = (filter) =>
  filter?.reduce((accumulator, rule) => {
    const value = `%${rule.value ? rule.value : ""}%`;
    return {
      ...accumulator,
      [rule.columnField]: {
        like: value,
      },
    };
  }, {});