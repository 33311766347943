import "./App.css";
import { apps } from "@neoco/neoco-backoffice";
import { headers as loginHeaders } from "./pages/Login";
import { headers as products } from "pages/Products";
import { headers as customers } from "pages/Customers";
const { App } = apps;

const DummyApp = () => {
  return <App headers={[loginHeaders, products, customers]} />;
};

export default DummyApp;
