const request = (
    url,
    { API_URL = process.env.REACT_APP_API_URL, ...options } = {}
  ) =>
    fetch(`${API_URL}${url}`, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      switch (res.status) {
        case 200:
          return res.json();
        case 403:
          localStorage.removeItem("token");
          localStorage.setItem("isLoggedIn", false);
          localStorage.setItem("user", {});
          window.location = "/";
          return;
        default:
          return res
            .json()
            .then((error) => Promise.reject({ status: res.status, error }));
      }
    });
  
  export default request;